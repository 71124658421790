import { getCartQnty } from "components/applications/cart/helpers/Cart.helpers";
import GraphCMS from "services/cms/GraphQL.service";
import GetEmailToasterRules from "services/cms/queries/GetEmailToasterRules";
import { setRejoinerCustomerCookie, prepareCartItem } from "./Rejoiner.helpers";



/**
 * Get the rules setup in the CMS for the email toaster modal
 * @returns esponse object
 */

export async function getCMSRejoinerRules(){
    const cmsGraphQl = new GraphCMS();
    const ruleSchema = GetEmailToasterRules();
    return await cmsGraphQl.post(ruleSchema).then(res => res?.data?.entry?.toasterRules[0])
}



/**
 * Add a customer to a list in Rejoiner
 * @param {object} data 
 * @param {string} list
 * @returns response object
 */

export async function addCustomerToList({ data, list }){
    return await fetch("/api/rejoiner/customerLists?list=" + list, { 
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: data 
    })
    .then(res => {
        setRejoinerCustomerCookie()

        return res.json()
    })
    .catch(err => {
        console.log(err)
    })
}




/**
 * Set track the product view for Rejoiner
 * @param {object} productData 
 */

export function setRejoinerProduct(productData) {
    try{
        const { product_id, name, price, product_uri, categories, images } = productData;

        _rejoiner.push(['trackProductView', { 
            product_id, 
            name,
            price: Math.round(parseFloat(price)*100),
            image_url: Array.isArray(images) ? images[0]?.image_url : images,
            product_url: process.env.NEXT_PUBLIC_siteUrl +"/"+ product_uri,
            category: categories.map(category => category?.name),
        }]);

    }catch(err){}
}



/**
 * Set a new customer for Rejoiner
 * @param {object} customerData 
 */

export function setRejoinerCustomer(customerData){
    try{
        _rejoiner.push(['setCustomerData', { ...customerData }]);
        _rejoiner.push(['setCustomerEmail', {'email': customerData.email }]);

        setRejoinerCustomerCookie()

    }catch(err){}
}



/**
 * Set the cart data for Rejoiner
 * @param {object} cartData 
 */

export function setRejoinerCheckout(cartData= null, prodCat){
    try{
        const { cart_amount, id, line_items } = (cartData?.data) ? cartData?.data : cartData;

        const newCartData = {
            cart_value: Math.round(parseFloat(cart_amount)*100),
            cart_item_count: getCartQnty(cartData.data),
            promo: null,
            return_url: process.env.NEXT_PUBLIC_siteUrl + `/cart?id=${id}`,
            order_number: id
        }

        _rejoiner.push(['setCartData', newCartData]);


        line_items?.gift_certificates?.forEach(item => {
            _rejoiner.push(['setCartItem', prepareCartItem(true, item)]);
        });

        line_items?.physical_items?.forEach(item => {
            _rejoiner.push(['setCartItem', prepareCartItem(false, item, prodCat)]);
        });
    
    
    }catch(err){}
}



/**
 * remove a removed product from the cart for Rejoiner
 * @param {string} product_id 
 */

export function removeRejoinerCartItem(product_id){
    try{
        _rejoiner.push(['removeCartItem', { product_id }]);

    }catch(err){}
    
}



/**
 * Clear the cart data for Rejoiner
 */

export function clearRejoinerCart(){
    try{
        _rejoiner.push(['clearCartData']);

    }catch(err){}
}

//Check if user exists in Rejoiner Lists

async function checkIfExists({ email, phone }) {
    try {
        const response = await fetch(`/api/rejoiner/check-existing`, {
            method: "POST", 
            body: JSON.stringify({ email, phone }), 
            headers: {"Content-Type": "application/json"},
        });

        return await response.json();
    } catch (error) {
        //console.error("Error checking if user exists", error);
        return { exists: false };
    }
}

//Sends email to Rejoiner if user exits during step 2.
export async function postForm({ 
    formData,
    setLoading,
    setSuccess,
    setFormStep,
    setUserEmail,
    isMobile,
}) {
    setLoading(true);
    const { email } = formData;
    setUserEmail(email);

    if (isMobile) {
        const exists = await checkIfExists({ email });

        if (!exists) {
            await addCustomerToList({
                data: JSON.stringify({ email }),
                list: process.env.NEXT_PUBLIC_rejoiner_email_list,
            });
            setSuccess(true);
        }
    }

    setLoading(false);
    setFormStep(2);
}

//Sends email and phone number to Rejoiner
export async function submitPhoneForm({
    formData,
    setLoading,
    setSuccess,
    setError,
    onSuccess,
    userEmail,
    setIsAlreadySubscribed
}) {
    if (!userEmail) return;
    setLoading(true);

    const { phone } = formData;

    const existsResponse = await checkIfExists({ email: userEmail, phone });


    if (existsResponse.exists) {
        console.log("User already exists in the phone list. Showing 'Already Subscribed' message.");
        setIsAlreadySubscribed(true);
        setLoading(false);
        onSuccess();
        return;
    }

    console.log("User does not exist in the phone list. Proceeding to add...");

    try {
        const response = await addCustomerToList({
            data: JSON.stringify({ email: userEmail, phone }),
            list: process.env.NEXT_PUBLIC_rejoiner_phone_list,
        });

       // console.log("API Response from addCustomerToList:", response);

        // If Rejoiner API returns a duplicate message, load the already subscribed modal
        if (response?.__all__) {
            console.log("Duplicate detected (email or phone). Triggering isAlreadySubscribed modal...");
            setIsAlreadySubscribed(true);
            setLoading(false);
            onSuccess();
            return;
        }

        // If no duplicates, load Confirmation modal
        if (response?.customer) {
            console.log("Successfully added to phone list.");
            setLoading(false);
            setSuccess(true);
            setIsAlreadySubscribed(false);
            onSuccess();
        } else {
            console.log("Error: Failed to add user to the list. API Response:", response);
            setLoading(false);
            setError(true);
        }
    } catch (err) {
        console.error("Catch Error in submitPhoneForm:", err);
        setError(true);
        setLoading(false);
    }
}

export async function sendEmailToList(email) {
    const existsResponse = await checkIfExists({ email: email });
    const userExists = existsResponse?.exists;

    if (!userExists) {
        addCustomerToList({
            data: JSON.stringify({email: email}),
            list: process.env.NEXT_PUBLIC_rejoiner_email_list,
        });
        //console.log('Added to email list on step 2 exit (desktop - closeModal)')
    }
}



