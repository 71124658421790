
/**
 *  Get the rules setup in the CMS for the email toaster modal
 * @returns string
 */

export default function GetEmailToasterRules() {
    return `query EmailToasterRuleQuery {
                entry(section: "emailToaster") {
                    ... on emailToaster_Entry {
                        toasterRules {
                        ... on toasterRulesBlock_Entry {
                                id
                                frequency
                                doNotShow
                                delay
                                mobileImage {
                                    height
                                    width
                                    filename
                                    id
                                    srcset(sizes: "400")
                                    url
                                }
                                image {
                                    height
                                    width
                                    filename
                                    id
                                    srcset(sizes: "400")
                                    url
                                }
                            }
                        }
                    }
                }
            }`
}

