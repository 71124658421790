import { LocalStorage, storageKeys } from "services/LocalStorage.service"
import { giftcardImg } from "lib/Constants"

/**
 * validates if the current path is in the list of paths to not show the modal 
 * @param {string} path 
 * @param string rules 
 * @returns boolean
 */


export function validatePaths(path="", rules="" ){
    const doNotShow = rules?.split(",").map(item => item.trim()) ?? []

    if( doNotShow.length ){
        return doNotShow.some(item => {
            const newPath = path.split("/").filter(item => item !== "")
            const newItem = item.split("/").filter(item => item !== "")

            return newPath.toString() === newItem.toString()
        })
    }

    return false
}




/**
 * set secure cookie that expires in 5 years to indicate that this rejoiner profile has been created
 * @returns cookie
 */

export function setRejoinerCustomerCookie(){
    const date = new Date();
    date.setTime(date.getTime() + (5 * 365 * 24 * 60 * 60 * 1000));

    const expires = "; expires=" + date.toGMTString();
    document.cookie = `${storageKeys.rejoiner.profile}=true` + expires + "; path=/;secure=true";

    return;
}



/**
 * set cookie with time to watch to reopen modal in 15 days
 * @param {object} rules 
 * @returns cookie
 */

export function setRejoinerDismissedCookie(rules){
    const date = new Date();
    date.setTime(date.getTime() + ((rules?.frequency ?? 15) * 24 * 60 * 60 * 1000));

    const expires = "; expires=" + date.toGMTString();
    document.cookie = `${storageKeys.rejoiner.toaster}=isDismissed` + expires + "; path=/;secure=true";

    return;
}

// Helper function to prepare cart item data
export const prepareCartItem = (isGiftCertificate = false, item, prodCat = {}) => {
    const {
        product_id, 
        name, 
        image_url, 
        sale_price, 
        quantity, 
        extended_sale_price, 
        url, 
        amount 
    } = item;
    const siteUrl = process.env.NEXT_PUBLIC_siteUrl;
    const gc_imgUrl = giftcardImg;

    let price = isGiftCertificate ? amount : sale_price;
    let qty_price = isGiftCertificate ? amount : extended_sale_price;
    let category = isGiftCertificate ? 'giftCertificates' : (prodCat.hasOwnProperty(product_id) && Array.isArray(prodCat[product_id]) ? prodCat[product_id].map(category => category?.name) : null);
    let productUrl = isGiftCertificate ? siteUrl + "/p/gift-card/" : siteUrl + "/" + url.split("https://secure.authenteak.com/")[1];
    let imageUrl = isGiftCertificate ? gc_imgUrl : image_url;
    
    return {
        product_id: isGiftCertificate ? 'giftCertificate' : product_id,
        name,
        price: Math.round(parseFloat(price) * 100),
        description: null,
        category,
        item_qty: quantity,
        qty_price: Math.round(parseFloat(qty_price) * 100),
        product_url: productUrl,
        image_url: imageUrl,
        expiration_date: null
    };
};