import { removeRejoinerCartItem } from "thirdparty/Rejoiner/Rejoiner.service";


/**
 * Checks to see if anything is in the cart
 * @param {object} cartData 
 * @returns boolean
 */

export function checkForAnythingInCart(cartData){
    if( cartData?.line_items === undefined ) return false;

    const cartItems = Object.values(cartData?.line_items ?? {}).map(item => item)
    return cartItems.some(item => item.length > 0)
}





/**
 * Sort and add up cart totals
 * @param {object} cartData 
 * @returns {object} { total, subTotal, planTotal, cartItems }
 */

export function processCartItems(cartData){
    let total = 0;
    let subTotal = 0;
    let planTotal = 0;
    const items = [];
    let shipping = 9.95;
    let hasSwatchRequest = false;
    
    const physicalItems = [...cartData?.line_items?.physical_items ?? cartData?.cart?.line_items?.physical_items];
    const customItems = [...cartData?.line_items?.custom_items ?? cartData?.cart?.line_items?.custom_items];
    const giftCardItems = [...cartData?.line_items?.gift_certificates ?? cartData?.cart?.line_items?.gift_certificates];


    if( physicalItems.length ){
        physicalItems.forEach(item => { 
            total = (item.quantity * item.list_price) + total;
            subTotal = (item.quantity * item.list_price) + subTotal;

            items.push(item)
        });
    }

    if( customItems.length ){                        
        customItems.forEach(item => {
            total = (item.quantity * item.list_price) + total;
           
            // check for protection plans
            if( item.sku.includes(";xtd;LEAD::") ){
                planTotal = (item.quantity * item.list_price) + planTotal;
            }

            // check for swatch requests = $10
            if( item.name.toLowerCase().includes("swatch")){
                total = 10 + total;
                subTotal = 10 + subTotal;
                hasSwatchRequest = true;
            }

            items.push(item)
        })
    }

    if( giftCardItems.length ){
        giftCardItems.forEach(item => {
            total = (item.quantity * item.amount) + total;
            subTotal = (item.quantity * item.amount) + subTotal;

            items.push(item)
        })
    }


    if( subTotal >= 200 || (hasSwatchRequest && subTotal === 10) ){
        shipping = 0;
    }

    debugger

    return {
        total, 
        subTotal, 
        planTotal,
        items,
        shipping
    }
}




/**
 * Luhn Credit Card Initial Verification
 * @param {Number} num 
 * @returns CC Validation Pass/Fail Boolean
 */

export function luhnCheck(num){
    const arr = (num + '').split('').reverse().map(x => parseInt(x));
    const lastDigit = arr.splice(0, 1)[0];
    let sum = arr.reduce((acc, val, i) => (i % 2 !== 0 ? acc + val : acc + ((val * 2) % 9) || 9), 0);

    sum += lastDigit;

    return sum % 10 === 0;
}







/**
 * cart the quantity of products in a cart
 * @param {object} cart 
 * @returns {number} count
 */

export function getCartQnty(cart){
    if( !cart?.line_items ){ return 0; }

    const cartItems = cart?.line_items;
    const products = [].concat(cartItems?.physical_items, cartItems?.custom_items, cartItems?.gift_certificates);
    const productQuantities = products.map(item => item?.quantity ?? 0)

    const count = productQuantities.reduce((prev, curr) => prev + curr);

    return count;
}





/**
 * remove this item
 * @param {string} id
 * @param {string} cartId
 * @param {object} itemMutation
 * @param {number} product_id 
 * @param {object} product_plan_id - protection plan id
 */

export function removeCartItem({
    id, 
    cartId, 
    itemMutation, 
    product_id = null,
    product_plan_id = null,
    callback = () => null,
    type = "remove"
}){

    if( product_id ){
        removeRejoinerCartItem(product_id)
    }

    itemMutation.mutate({ 
        productId: id, 
        cartId, 
        type, 
        id: product_id,
        product_plan_id
    }, {
        onError: (error, variables, context) => {
            console.log(error)
            console.log(variables)
        },
        onSuccess: async data => {
            if( data?.response?.status === 404 && product_id ){
                const reactToast = await import("react-toastify");
                reactToast.toast.error(`Error removing this product from cart`)   

            }else{
                debugger
                callback(data)
            }
        }
    })
}







/**
 * Remove custom cart fees
 * @param {string} feeIds
 * @param {string} cartId
 * @param {object} itemMutation
 * @param {function} callback 
 */

export function removeFee({
    feeIds,
    cartId,
    itemMutation,
    callback
}){

    itemMutation.mutate({
        type: "removeFee",
        feeIds,
        cartId
    },{
        onSettled: () => callback(),
        onSuccess: async data => callback(data)
    })

}